<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nova Unidade" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <!--<Button
                label="Apagar"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              />-->
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <DataTable exportFilename="relatorio[UNIDADES]" csvSeparator=";" ref="dt" :value="unidades"
          v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="5" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="
                        flex flex-column
                        md:flex-row md:justify-content-between md:align-items-center
                      ">
              <h5 class="m-0">Gereneciar Unidades</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="unidade" header="Unidade" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Unidade</span>
              {{ slotProps.data.unidade }}
            </template>
          </Column>
          <Column field="tipo_tensao" header="Tipo Tensão" :sortable="true" headerStyle="width:20%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">tipo_tensao</span>
              {{ slotProps.data.tipo_tensao }}
            </template>
          </Column>
          <Column field="classe_tarifaria" header="Classe Tarifária" :sortable="true"
            headerStyle="width:20%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">classe_tarifaria</span>
              {{ slotProps.data.classe_tarifaria }}
            </template>
          </Column>
          <Column field="tensao_forencimento" header="Tensão Fornecimento" :sortable="true"
            headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">tensao_forencimento</span>
              {{ slotProps.data.tensao_forencimento }}
            </template>
          </Column>
          <Column field="subgrupo_tarifario" header="Sub Grupo Tarifário" :sortable="true"
            headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">subgrupo_tarifario</span>
              {{ slotProps.data.subgrupo_tarifario }}
            </template>
          </Column>
          <Column field="sub_classe" header="Sub Classe" :sortable="true"
            headerStyle="width:30%; min-width:18rem;   white-space: nowrap;">
            <template #body="slotProps">
              <span class="p-column-title" style="  white-space: nowrap;">sub_classe</span>
              {{ slotProps.data.sub_classe }}
            </template>
          </Column>
          <Column field="sigla" header="Concessionária" :sortable="true" headerStyle="">
            <template #body="slotProps">
              <span class="p-column-title">sigla</span>
              {{ slotProps.data.sigla }}
            </template>
          </Column>
          <Column field="cnpj" header="CNPJ" :sortable="true" headerStyle="width:30%; min-width:11rem;">
            <template #body="slotProps">
              <span class="p-column-title">cnpj</span>
              {{ slotProps.data.cnpj }}
            </template>
          </Column>
          <Column field="rua" header="Endereço" :sortable="true" headerStyle="width:30%; min-width:20rem;">
            <template #body="slotProps">
              <span class="p-column-title">rua</span>
              {{ slotProps.data.rua }}
            </template>
          </Column>

          <Column field="numero" header="Número" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">numero</span>
              {{ slotProps.data.numero }}
            </template>
          </Column>

          <Column field="bairro" header="Bairro" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">bairro</span>
              {{ slotProps.data.bairro }}
            </template>
          </Column>

          <Column field="cep" header="CEP" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">cep</span>
              {{ slotProps.data.cep }}
            </template>
          </Column>

          <Column field="cidade" header="Cidade" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">cidade</span>
              {{ slotProps.data.cidade }}
            </template>
          </Column>

          <Column field="estado" header="Estado" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">estado</span>
              {{ slotProps.data.estado }}
            </template>
          </Column>

          <Column field="usina" header="Usina" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">usina</span>
              {{ slotProps.data.usina }}
            </template>
          </Column>

          <Column field="valor_rateio" header="Rateio" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">valor_rateio</span>
              {{ slotProps.data.valor_rateio }}
            </template>
          </Column>

          <Column field="data_inicio" header="Data Início" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">data_inicio</span>
              {{ slotProps.data.data_inicio }}
            </template>
          </Column>



          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                @click="apagarUnidadeModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="productDialog" :style="{ width: '900px' }" header="Gerenciar Unidade" :modal="true"
          class="p-fluid">
          <div class="grid">
            <div class="col-3">
              <div class="field">
                <label for="contrato_re">Unidade</label>
                <InputText id="controle_re" v-model.trim="unidade.unidade" required="true" autofocus
                  :class="{ 'p-invalid': submitted && !unidade.unidade }" />
              </div>
            </div>
            <div class="col-3">
              <div class="field">
                <label for="razao_social">Concessionária</label>
                <Dropdown id="status" v-model="unidade.id_concessionaria" :options="concessionarias" optionLabel="label"
                  optionValue="value" placeholder="Selecione uma Concessionária">
                </Dropdown>
              </div>
            </div>
            <div class="col-3">
              <div class="field">
                <label for="contrato_re">Tipo de Tensão</label>
                <Dropdown id="status" v-model="unidade.id_tipo_fornecimento" :options="tipos_tensao" optionLabel="label"
                  optionValue="value" placeholder="Selecione Tipo de Tensão">
                </Dropdown>
              </div>
            </div>
            <div class="col-3">
              <div class="field">
                <label for="contrato_re">Classe Tarifária</label>
                <Dropdown id="status" v-model="unidade.id_classe_tarifaria" :options="classes_tarifaria"
                  optionLabel="label" optionValue="value" placeholder="Selecione Classe Tarifária">
                </Dropdown>
              </div>
            </div>
            <div class="col-3">
              <div class="field">
                <label for="ordem_sap">Tensão de Fornecimento</label>
                <Dropdown id="status" v-model="unidade.id_tipo_tensao" :options="tipos_tensao_fornecimento"
                  optionLabel="label" optionValue="value" placeholder=" Selecione Tensão de Fornecimento">
                </Dropdown>
              </div>
            </div>
            <div class="col-3">
              <div class="field">
                <label for="ordem_sap">Sub Grupo Tarifário</label>
                <Dropdown id="status" v-model="unidade.id_sub_grupo_tarifario" :options="sub_grupos_tarifarios"
                  optionLabel="label" optionValue="value" placeholder=" Selecione Sub Grupo Tarifário">
                </Dropdown>
              </div>
            </div>
            <div class="col-3">
              <div class="field">
                <label for="ordem_sap">Sub Classe</label>
                <Dropdown id="status" v-model="unidade.id_sub_classe" :options="sub_classes" optionLabel="label"
                  optionValue="value" placeholder="Sub Classe">
                </Dropdown>
              </div>
            </div>
            <div class="col-3">
              <div class="field">
                <label for="ordem_sap">CNPJ</label>
                <InputText id="ordem_sap" v-model.trim="unidade.cnpj" required="true" autofocus
                  :class="{ 'p-invalid': submitted && !unidade.cnpj }" />
              </div>
            </div>
          </div>
          <hr />
          <div class="grid">
            <div class="col-6">
              <div class="field">
                <label for="endereco">Endereço</label>
                <InputText id="endereco" v-model.trim="unidade.rua" required="true" autofocus
                  :class="{ 'p-invalid': submitted && !unidade.rua }" />
              </div>
            </div>
            <div class="col-2">
              <div class="field">
                <label for="numero">Número</label>
                <InputText id="numero" v-model.trim="unidade.numero" required="true" autofocus
                  :class="{ 'p-invalid': submitted && !unidade.numero }" />
              </div>
            </div>


            <div class="col-4">
              <div class="field">
                <label for="bairro">Bairro</label>
                <InputText id="bairro" v-model.trim="unidade.bairro" required="true" autofocus
                  :class="{ 'p-invalid': submitted && !unidade.bairro }" />
              </div>
            </div>
            <div class="col-4">
              <div class="field">
                <label for="cep">CEP</label>
                <InputMask id="cep" v-model.trim="unidade.cep" required="true" autofocus mask="99999-99"
                  :class="{ 'p-invalid': submitted && !unidade.cep }" />
              </div>
            </div>
            <div class="col-4">
              <div class="field">
                <label for="estados">Estado</label>
                <Dropdown id="estados" v-model="unidade.id_estado" :options="estados" optionLabel="label"
                  placeholder="Selecione um estado" optionValue="value" @change="trocarCidades()" :filter="true">
                </Dropdown>
              </div>
            </div>

            <div class="col-4">
              <div class="field">
                <label for="cidade">Cidade</label>
                <Dropdown id="status" v-model="unidade.id_cidade" :options="cidades" optionLabel="label"
                  optionValue="value" placeholder="Selecione uma cidade" :filter="true">
                </Dropdown>
              </div>
            </div>
          </div>
          <hr />
          <div class="grid">
            <div class="col-6">
              <div class="field">
                <label for="estado">Usina</label>
                <Dropdown id="status" v-model="unidade.id_usina" :options="usinas" optionLabel="label" optionValue="value"
                  placeholder="Selecione uma Usina">
                </Dropdown>
              </div>
            </div>

            <div class="col-2">
              <div class="field">
                <label for="rateio">Rateio</label>
                <InputText id="rateio" v-model.trim="unidade.valor_rateio" required="true" autofocus
                  :class="{ 'p-invalid': submitted && !unidade.valor_rateio }" />
              </div>
            </div>
            <div class="col-3">
              <div class="field">
                <label for="razao_social">Data Inicio</label>
                <InputText id="bairro" v-model.trim="unidade.data_inicio" required="true" autofocus
                  :class="{ 'p-invalid': submitted && !unidade.data_inicio }" />
              </div>
            </div>
          </div>
          <hr />
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarUnidade" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true" header="&nbsp;">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="unidade">Apagar: <b>{{ unidade.inscricao_imobiliaria }}</b>?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm"
              @click="deleteProductDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarUnidade" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="unidades">Apagar unidades selecionadas?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      prioridades: [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" }
      ],
      prioridade: "",
      unidades: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      unidade: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: 0,
      empresas: [],
      areas: [],
      regionais: [],
      tipos: [],
      estados: [],
      cidades: [],
      id_estado: "",
      id_cidade: "",
      statuses: [
        { label: "Inativo", value: "0" },
        { label: "Ativo", value: "1" },
      ],
      concessionarias: [],
      tipos_tensao: [],
      tipos_tensao_fornecimento: [],
      classes_tarifaria: [],
      sub_classes: [],
    };
  },
  productService: null,
  created() {
    this.initFilters();
  },
  mounted() {
    this.listarEmpresa();
    this.listarConcessionarias();
    this.listarUsinas();
    this.listarTiposTensao();
    this.listarTiposTensaoFornecimento();
    this.listarEstados();
    this.listarCidades();
    this.listarUnidade();
    this.listarClassesTarifias();
    this.listarSubClasses();
    //this.productService.getProducts().then((data) => (this.products = data));
  },
  methods: {
    listarClassesTarifias() {
      this.axios
        .post("/unidades/dados/tipos-classe-tarifaria/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["descricao"] };
            options.push(option);
          });
          this.classes_tarifaria = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarSubClasses() {
      this.axios
        .post("/unidades/dados/tipos-sub-classe/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["descricao"] };
            options.push(option);
          });
          this.sub_classes = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarTiposTensao() {
      this.axios
        .post("/unidades/dados/tipos-tensao/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["descricao"] };
            options.push(option);
          });
          this.tipos_tensao = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarTiposTensaoFornecimento() {
      this.axios
        .post("/unidades/dados/tipos-tensao-fornecimento/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["descricao"] };
            options.push(option);
          });
          this.tipos_tensao_fornecimento = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarConcessionarias() {
      this.axios
        .post("/concessionarias/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["sigla"] };
            options.push(option);
          });
          this.concessionarias = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarUsinas() {
      this.axios
        .post("/usinas/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["sigla"] };
            options.push(option);
          });
          this.usinas = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarRegionais() {
      this.axios
        .post("/regional/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["titulo"] };
            options.push(option);
          });
          this.regionais = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    trocarCidades() {
      console.log(this.unidade.id_estado);
      this.listarCidades(this.unidade.id_estado);
    },
    listarCidades() {
      let data = {
        state_id: this.unidade.id_estado,
      };
      this.axios
        .post("/auxiliar/cidades", data)
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["nome"] };
            options.push(option);
          });
          this.cidades = options;
          console.log(this.unidade.id_cidade);
          let set = this.unidade.id_cidade;
          this.unidade.id_cidade = set;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarEstados() {
      this.axios
        .post("/auxiliar/estados")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["nome"] };
            options.push(option);
          });
          this.estados = options;
          this.id_estado = 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarTipos() {
      this.axios
        .post("/tipos/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["titulo"] };
            options.push(option);
          });
          this.tipos = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarEmpresa() {
      this.axios
        .post("/empresas/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["razao_social"] };
            options.push(option);
          });
          this.empresas = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarUnidade() {
      this.axios
        .post("/unidades/listar")
        .then((response) => {
          console.log(response.data.dados);
          this.unidades = response.data.dados;
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    trocarStatus() {
      this.unidade.status = this.status.value;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      return;
    },
    openNew() {
      this.unidade = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    salvarUnidade() {
      this.submitted = true;

      if (this.unidade.id) {
        this.axios
          .post("/unidades/editar", this.unidade)
          .then(() => {
            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });

        let dadosbasicos = {
          id: this.unidade.id_dados_basicos,
          id_unidade: this.unidade.id,
          id_tipo_fornecimento: this.unidade.id_tipo_fornecimento,
          id_classe_tarifaria: this.unidade.id_classe_tarifaria,
          id_tipo_tensao: this.unidade.id_tipo_tensao,
          id_sub_grupo_tarifario: this.unidade.id_sub_grupo_tarifario,
          id_sub_classe: this.unidade.id_sub_classe,
          id_cidade: this.unidade.id_cidade,
          id_estado: this.unidade.id_estado
        }

        this.axios
          .post("/unidades/dados/editar", dadosbasicos)
          .then(() => {
            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });

        let dadosEndereco = {
          id: this.unidade.id_endereco,
          id_unidade: this.unidade.id,
          id_tipo: 2,
          rua: this.unidade.rua,
          numero: this.unidade.numero,
          bairro: this.unidade.bairro,
          id_cidade: this.unidade.id_cidade,
          id_estado: this.unidade.id_estado,
          cep: this.unidade.cep,
        }

        this.axios
          .post("/unidades/enderecos/editar", dadosEndereco)
          .then(() => {

            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });


        let dadosRateio = {
          id: this.unidade.id_rateio,
          id_unidade: this.unidade.id,
          valor_rateio: this.unidade.valor_rateio,
          data_inicio: this.unidade.data_inicio
        }

        this.axios
          .post("/unidades/rateio/editar", dadosRateio)
          .then(() => {

            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });

        this.listarUnidade();

      } else {

        this.axios
          .post("/unidades/adicionar", this.unidade)
          .then((response) => {

            let id = response.dados.id;
            let dadosbasicos = {
              id_unidade: id,
              id_tipo_fornecimento: this.unidade.id_tipo_fornecimento,
              id_classe_tarifaria: this.unidade.id_classe_tarifaria,
              id_tipo_tensao: this.unidade.id_tipo_tensao,
              id_sub_grupo_tarifario: this.unidade.id_sub_grupo_tarifario,
              id_sub_classe: this.unidade.id_sub_classe,
              id_cidade: this.unidade.id_cidade,
              id_estado: this.unidade.id_estado
            }

            this.axios
              .post("/unidades/dados/adicionar", dadosbasicos)
              .then(() => {
                this.productDialog = false;
                this.unidade = {};
              })
              .catch(() => {
                this.$toast.add({
                  severity: "error",
                  summary: "Ops!",
                  detail: "Chamar o T.I.",
                  life: 3000,
                });
              });

            let dadosEndereco = {
         
              id_unidade: id,
              id_tipo: 2,
              rua: this.unidade.rua,
              numero: this.unidade.numero,
              bairro: this.unidade.bairro,
              id_cidade: this.unidade.id_cidade,
              id_estado: this.unidade.id_estado,
              cep: this.unidade.cep,
            }

            this.axios
              .post("/unidades/enderecos/adicionar", dadosEndereco)
              .then(() => {

                this.productDialog = false;
                this.unidade = {};
              })
              .catch(() => {
                this.$toast.add({
                  severity: "error",
                  summary: "Ops!",
                  detail: "Chamar o T.I.",
                  life: 3000,
                });
              });


            let dadosRateio = {
              id_unidade: id,
              valor_rateio: this.unidade.valor_rateio,
              data_inicio: this.unidade.data_inicio
            }

            this.axios
              .post("/unidades/rateio/adicionar", dadosRateio)
              .then(() => {

                this.productDialog = false;
                this.unidade = {};
              })
              .catch(() => {
                this.$toast.add({
                  severity: "error",
                  summary: "Ops!",
                  detail: "Chamar o T.I.",
                  life: 3000,
                });
              });

            this.listarUnidade();

            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },
    editProduct(unidade) {
      this.productDialog = true;

      this.unidade = { ...unidade };
      this.listarCidades(this.unidade.id_estado);
    },
    apagarUnidadeModal(unidade) {
      this.unidade = unidade;
      this.deleteProductDialog = true;
    },
    apagarUnidade() {
      this.axios
        .post("/unidades/apagar", this.unidade)
        .then(() => {
          this.listarUnidade();
          this.deleteProductDialog = false;
          this.unidade = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Unidade apagada com sucsso!",
            life: 3000,
          });
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      let obj = this.selectedProducts;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/unidades/apagar", obj[key])
          .then(() => {
            this.listarUnidade();
            this.deleteProductDialog = false;
            this.unidade = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usário apagado com sucesso!s",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Unidades apagada com sucesso!",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
